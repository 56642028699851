// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BlockHeading_heading__y1r6G {\n    font: var(--comp-lander-text-desktop-block-header, 700 2rem/3rem serif);\n    text-align: var(--comp-lander-alignment-block-header-default, left);\n    margin: 0px 0px 18px 0px;\n}\n\n.BlockHeading_headingPositionWithCta__d1TGf {\n    text-align: var(--comp-lander-alignment-block-header-with-cta);\n}\n\n@media only screen and (max-width: 768.5px){\n    .BlockHeading_heading__y1r6G{\n        font: var(--comp-lander-text-mobile-block-header, 700 1.5rem/2.25rem serif);\n    }\n}", "",{"version":3,"sources":["webpack://./src/app/components/ContentTypeComponents/BlockHeading.module.css"],"names":[],"mappings":"AAAA;IACI,uEAAuE;IACvE,mEAAmE;IACnE,wBAAwB;AAC5B;;AAEA;IACI,8DAA8D;AAClE;;AAEA;IACI;QACI,2EAA2E;IAC/E;AACJ","sourcesContent":[".heading {\n    font: var(--comp-lander-text-desktop-block-header, 700 2rem/3rem serif);\n    text-align: var(--comp-lander-alignment-block-header-default, left);\n    margin: 0px 0px 18px 0px;\n}\n\n.headingPositionWithCta {\n    text-align: var(--comp-lander-alignment-block-header-with-cta);\n}\n\n@media only screen and (max-width: 768.5px){\n    .heading{\n        font: var(--comp-lander-text-mobile-block-header, 700 1.5rem/2.25rem serif);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "BlockHeading_heading__y1r6G",
	"headingPositionWithCta": "BlockHeading_headingPositionWithCta__d1TGf"
};
module.exports = ___CSS_LOADER_EXPORT___;
