/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import mbpLogger from 'mbp-logger';
import { func, object, string } from 'prop-types';

class ComponentErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, componentStack) {
        const {
            function: logFunction, module = '', message = '', onError,
        } = this.props;

        if (onError) {
            onError(error, componentStack, this.props);
        }
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            message: message || 'Error Boundary Triggered',
            module: module || 'ComponentErrorBoundary',
            function: logFunction || 'unknown',
            error,
            componentStack,
        });
    }

    render() {
        const { children, fallback } = this.props;
        const { hasError } = this.state;
        let component = children;
        if (hasError) {
            if (fallback && typeof fallback === 'function') {
                component = fallback();
            } else {
                component = null;
            }
        }
        return component;
    }
}

ComponentErrorBoundary.propTypes = {
    children: object.isRequired,
    function: string,
    module: string.isRequired,
    message: string,
    onError: func,
    fallback: func,
};

ComponentErrorBoundary.defaultProps = {
    function: '',
    message: '',
    onError: null,
    fallback: null,
};

export default ComponentErrorBoundary;
