/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { object } from 'prop-types';
import { useDispatch } from 'react-redux';
import GiftFinderProductQuery from './GiftFinderProductQuery';
import { parseFont } from '../../../helpers/common/helper';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles(() => ({
    container: {
        overflow: 'hidden',
        height: 'auto',
        transition: 'height 0.2s ease-in-out 0s', /* You can adjust the duration and easing function */
        maxWidth: '874px',
        width: '100%',
        padding: '0px 10px',
        '@media (max-width: 767px)': {
            width: 'auto',
        },
    },
    content: {
        padding: ' 10px',
        border: '1px solid #ccc',
        backgroundColor: ' #f5f5f5',
    },
    button: {
        maxWidth: '424px',
        background: 'none',
        cursor: 'pointer',
        display: 'flex',
        padding: '11px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
        borderRadius: '3px',
        margin: '0 auto',
        '&:hover, &:focus, &:active': {
            outline: 'none',
        },
        textAlign: 'center',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.64px',
    },
    icon: {
        display: 'flex',
    },
    heading: {
        textAlign: 'center',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '26px',
        letterSpacing: '0.2px',
        position: 'relative',
        padding: '29px 0px',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '@media (max-width: 767px)': {
            gap: '12px',
            padding: '16px 0px 35px',
        },
    },
    buttonContainer: {
        position: 'relative',
    },
    iconAdjust: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 767px)': {
            position: 'relative',
        },
    },
    divider: {
        color: '#000',
        textAlign: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.56px',
        margin: '20px 0px',
        '@media (max-width: 767px)': {
            margin: '18px 0px 25px',
        },
    },
    dividerLine: {
        backgroundColor: '#DABECD',
        height: '1px',
        margin: '18px 0px 0px',
        '@media (max-width: 767px)': {
            margin: '16px 0px 0px',
        },
    },
}));
const GiftFinderProductContainer = ({ productData }) => {
    const classes = useStyles();
    const stylingObj = productData?.styling || {};

    const recommendationsList = (data) => {
        if (!data) {
            return [];
        }
        if (Array.isArray(data)) {
            return data;
        }
        return Object.values(data);
    };
    const partNumbers = recommendationsList(productData?.recommendations);

    if (!partNumbers || partNumbers.length < 1) return null;

    const arrPartNumbers = `["${partNumbers.join('","')}"]`;

    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
        dispatch(trackEvent({
            eventCategory: 'Gift Finder FLR',
            eventAction: isCollapsed ? 'Collapse' : 'Expand',
            eventLabel: 'Accordion',
        }));
    };
    const chevronUp = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3813 9.88128C13.723 9.53957 14.277 9.53957 14.6187 9.88128L21.6187 16.8813C21.9604 17.223 21.9604 17.777 21.6187 18.1187C21.277 18.4604 20.723 18.4604 20.3813 18.1187L14 11.7374L7.61872 18.1187C7.27701 18.4604 6.72299 18.4604 6.38128 18.1187C6.03957 17.777 6.03957 17.223 6.38128 16.8813L13.3813 9.88128Z" fill="#2F363D" />
        </svg>
    );
    const chevronDown = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill={stylingObj?.cta_copy_color?.color}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0303 15.5303C12.7374 15.8232 12.2626 15.8232 11.9697 15.5303L5.96967 9.53033C5.67678 9.23744 5.67678 8.76256 5.96967 8.46967C6.26256 8.17678 6.73744 8.17678 7.03033 8.46967L12.5 13.9393L17.9697 8.46967C18.2626 8.17678 18.7374 8.17678 19.0303 8.46967C19.3232 8.76256 19.3232 9.23744 19.0303 9.53033L13.0303 15.5303Z" fill={stylingObj?.cta_copy_color?.color} />
        </svg>
    );

    const expandButton = (isCollapsed
        ? (
            <>
                <div className={`${classes.dividerLine} ${classes.divider}`} />
                <div
                    style={{
                        fontFamily: parseFont(stylingObj.heading_font),
                    }}
                    className={classes.heading}
                >{productData.heading_copy} <span className={`${classes.icon} ${classes.iconAdjust}`}>{chevronUp()}</span>
                </div>
            </>
        ) : (
            <>
                <div className={`${classes.dividerLine} ${classes.divider}`} />
                <div className={classes.divider}>- or -</div>
                <div
                    className={classes.button}
                    style={{
                        backgroundColor: stylingObj.cta_background_color?.color,
                        color: stylingObj.cta_copy_color?.color,
                        border: `1px solid ${stylingObj.cta_border_color?.color}`,
                    }}
                > { productData.cta_copy }
                    <span className={`${classes.icon}`}>{chevronDown()}</span>
                </div>
            </>
        ));

    return (
        <div className={`${classes.container}`}>
            <div role="presentation" className={classes.buttonContainer} onClick={() => toggleCollapse()}>
                {expandButton}
            </div>
            {isCollapsed && <GiftFinderProductQuery partNumbers={arrPartNumbers} availabilityData={productData?.availability} giftFinder /> }
        </div>
    );
};

GiftFinderProductContainer.propTypes = {
    productData: object.isRequired,
};

export default GiftFinderProductContainer;
