/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { shape, string } from 'prop-types';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import blockHeadingCss from './BlockHeading.module.css';
// helpers
import cssVariablesEnvDir from '../EnterpriseDesignSystem/helpers/cssVariablesEnvDir';
import useStyleLinkTag from '../EnterpriseDesignSystem/StyleLinkContext/useStyleLinkTag';
// redux
import { getBrandName, getBrandIdentifier } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';

const BlockHeading = ({
    heading,
}) => {
    if (!heading) return null;
    useStyles(blockHeadingCss);
    const brandDomainName = useSelector(getBrandName);
    const brandIdentifier = useSelector(getBrandIdentifier);
    useStyleLinkTag(`https://cdn1.${brandDomainName}.com/wcsstore/${brandIdentifier}/css/${cssVariablesEnvDir}/components/lander/lander.vars.css`);
    return (
        <>
            <Helmet><link rel="stylesheet" href={`https://cdn1.${brandDomainName}.com/wcsstore/${brandIdentifier}/css/${cssVariablesEnvDir}/components/lander/lander.vars.css`} /></Helmet>
            <h2 className={blockHeadingCss.heading}>{heading}</h2>
        </>
    );
};

BlockHeading.propTypes = {
    heading: string,
};
BlockHeading.defaultProps = {
    heading: '',
};
export default BlockHeading;
